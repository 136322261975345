import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  AccordionActions,
  Button,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useSimplux } from "@simplux/react";
import { claims } from "state/claims";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  iso8601ToDate,
  humanizeIso8601ToDateTime,
  isValidDate,
} from "core/date_utils";
import useStepStyles from "./step_styles";
import { updateCraftsmanJobAppointment } from "api/api.claims";
import { useTranslation } from "react-i18next";

const stepName = "step01";

const Step01InspectionDate = () => {
  const classes = useStepStyles();

  const expandedStep = useSimplux(claims.expandedStep);
  const claim = useSimplux(claims.detailClaim);
  const job = claim.craftsmanJob;

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    iso8601ToDate(job.appointment)
  );
  const [noAppointmentNeededChecked, setNoAppointmentNeededChecked] =
    useState(false);
  const [backdrop, setBackdrop] = useState(false);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const readOnly = job.state !== "Accepted";

  const save = async () => {
    let validDate = isValidDate(selectedDate);

    if (validDate || noAppointmentNeededChecked) {
      try {
        setBackdrop(true);

        if (noAppointmentNeededChecked) {
          await updateCraftsmanJobAppointment(claim.id, null);
          claims.setInspectionDate("");
        } else {
          let dateAsIso8601 = selectedDate.toISOString();
          await updateCraftsmanJobAppointment(claim.id, dateAsIso8601);
          claims.setInspectionDate(dateAsIso8601);
        }
      } finally {
        setBackdrop(false);
      }
    }
  };

  const { t } = useTranslation("translation", { useSuspense: false });

  return (
    <>
      <Backdrop open={backdrop} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Accordion
        expanded={expandedStep === stepName}
        onChange={() => claims.toggleExpandedStep(stepName)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${stepName}bh-content`}
          id={`${stepName}bh-header`}
        >
          <Typography className={classes.heading}>
            {t("step1.title")}
          </Typography>
          {job.appointmentEnteredAt && !job.appointment && (
            <Typography className={classes.secondaryHeading}>
              {t("step1.noAppointmentNeededShort")}
            </Typography>
          )}
          {job.appointmentEnteredAt && job.appointment && (
            <Typography className={classes.secondaryHeading}>
              {t("step1.appointment", {
                date: humanizeIso8601ToDateTime(job.appointment),
              })}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item sm={12}>
              <Typography>{t("step1.description")}</Typography>
            </Grid>
            <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    label={t("datumUhrzeit")}
                    value={selectedDate}
                    onChange={handleDateChange}
                    invalidDateMessage={t("invalidDateMessage")}
                    disabled={noAppointmentNeededChecked || readOnly}
                    format="dd.MM.yyyy HH:mm"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      readOnly={readOnly}
                      checked={noAppointmentNeededChecked || readOnly}
                      onChange={(event) =>
                        setNoAppointmentNeededChecked(event.target.checked)
                      }
                      name="noInspection"
                      color="primary"
                    />
                  }
                  label={t("step1.noAppointmentNeeded")}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </AccordionDetails>
        {!readOnly && (
          <>
            <Divider />
            <AccordionActions>
              <Button
                size="small"
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => save()}
              >
                {t("save")}
              </Button>
            </AccordionActions>
          </>
        )}
      </Accordion>
    </>
  );
};

export default Step01InspectionDate;
