import {
  BrowserCacheLocation,
  Configuration,
  ProtocolMode,
} from "@azure/msal-browser";
import Cookies from "js-cookie";

const getIdentityBackend = () => {
  switch (window.location.origin) {
    case "http://localhost:1340":
      const idpBackend = getLocalIdentityProvider();

      if (idpBackend) {
        console.log("Use local Identity Provider");
        return idpBackend;
      }

      return "https://auth.dev.scp.zuluplane.io";
    case "https://supply.gvb.local":
      return "https://auth.gvb.local";
    case "https://supply.dev.scp.zuluplane.io":
      return "https://auth.dev.scp.zuluplane.io"
    case "https://supply.test.gvb.ch":
      return "https://auth.test.gvb.ch"
    case "https://dev-supply.gvb.ch":
      return "https://dev-auth.gvb.ch";
    case "https://test-supply.gvb.ch":
      return "https://test-auth.gvb.ch";
    case "https://supply.gvb.ch":
      return "https://auth.gvb.ch";
  }
};

export class OidcConfiguration {
  public static stsAuthority = getIdentityBackend();
  public static clientId = "craftsman-portal";
  public static clientRoot = window.location.origin;
  public static clientScope = ["scp", "openid", "email", "profile"];
}

export const msalConfiguration: Configuration = {
  auth: {
    authority: OidcConfiguration.stsAuthority,
    clientId: OidcConfiguration.clientId,
    redirectUri: `${OidcConfiguration.clientRoot}/signin-callback`,
    postLogoutRedirectUri: `${OidcConfiguration.clientRoot}`,
    protocolMode: ProtocolMode.OIDC,
    knownAuthorities: [OidcConfiguration.stsAuthority],
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
  },
};

function getLocalIdentityProvider() {
  if (!document.cookie) {
    return undefined;
  }

  return Cookies.get("local-idp");
}
