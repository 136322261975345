import {
  createStyles,
  makeStyles,
  Theme,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

interface ModalCircularProgressProps {
  open?: boolean | undefined;
}
const ModalCircularProgress = ({ open }: ModalCircularProgressProps) => {
  const classes = useStyles();
  const openBackdrop = open !== undefined ? open : true;

  return (
    <Backdrop className={classes.backdrop} open={openBackdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default ModalCircularProgress;
