import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Divider,
  AccordionActions,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { useSimplux } from "@simplux/react";
import { claims } from "state/claims";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DropzoneDialog } from "material-ui-dropzone";
import useStepStyles from "./step_styles";
import { ClaimDto, ClaimAttachmentDto, ClaimAttachmentType } from "api/dtos";
import {
  getClaimAttachmentsWithType,
  uploadCraftsmanJobAttachment,
  getClaimAttachmentUrl,
  updateClosedAt,
} from "api/api.claims";
import { humanizeBytes } from "core/conversion_utils";
import { useParams } from "react-router-dom";
import { ClaimDetailUrlParams } from "./claim_detail_url_params";
import { useTranslation } from "react-i18next";

const stepName = "step06";

const Step06InvoiceAndClosing = () => {
  const { claimId } = useParams<ClaimDetailUrlParams>();

  const classes = useStepStyles();

  const claim = useSimplux(claims.detailClaim) as ClaimDto;
  const expandedStep = useSimplux(claims.expandedStep);

  const [backdrop, setBackdrop] = useState(false);
  const [invoiceByLetterPost, setInvoiceByLetterPost] = useState(
    claim.craftsmanJob.invoiceByLetterPost
  );
  const [uploadDialog, setUploadDialog] = useState(false);

  const [attachments, setAttachments] = useState<ClaimAttachmentDto[]>([]);

  const readOnly = claim.craftsmanJob.state !== "Fixed";

  useEffect(() => {
    const loadAttachments = async () => {
      let attachments = await getClaimAttachmentsWithType(
        claimId,
        ClaimAttachmentType.Invoice
      );
      setAttachments(attachments.claimAttachments);
    };

    loadAttachments();
  }, [claimId]);

  const uploadFiles = (files: File[]) => {
    let promises = [];

    files.forEach(async (file) => {
      promises.push(
        uploadCraftsmanJobAttachment(
          claim.id,
          file,
          ClaimAttachmentType.Invoice
        )
      );
    });

    Promise.all(promises).then(() => {
      getClaimAttachmentsWithType(claim.id, ClaimAttachmentType.Invoice).then(
        (response) => setAttachments(response.claimAttachments)
      );
    });
  };

  const closeClaim = async () => {
    try {
      setBackdrop(true);
      await updateClosedAt(claim.id, invoiceByLetterPost);
      claims.setClosed();
    } finally {
      setBackdrop(false);
    }
  };

  const { t } = useTranslation("translation", { useSuspense: false });

  return (
    <>
      <Backdrop open={backdrop} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Accordion
        expanded={expandedStep === stepName}
        onChange={() => claims.toggleExpandedStep(stepName)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${stepName}bh-content`}
          id={`${stepName}bh-header`}
        >
          <Typography className={classes.heading}>
            {t("step6.title")}
          </Typography>
          {claim.craftsmanJob.closedAt && (
            <Typography className={classes.secondaryHeading}>
              {t("completed")}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Typography>{t("step6.additionalDocumentsText")}</Typography>
            </Grid>
            <Grid item sm={12}>
              <Button
                variant="contained"
                color="primary"
                disabled={readOnly}
                onClick={() => setUploadDialog(true)}
              >
                {t("upload")}
              </Button>
              <DropzoneDialog
                open={uploadDialog}
                cancelButtonText={t("step6.dropzoneCancel")}
                submitButtonText={t("step6.dropzoneSubmit")}
                previewText={t("step6.dropzonePreview")}
                dropzoneText={t("step6.dropzoneText")}
                maxFileSize={1024 * 1024 * 125}
                onClose={() => setUploadDialog(false)}
                onSave={(files) => {
                  uploadFiles(files);
                  setUploadDialog(false);
                }}
                filesLimit={10}
                showPreviews={true}
                showFileNamesInPreview={true}
              />
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h6">{t("uploadedFiles")}</Typography>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("file")}</TableCell>
                      <TableCell align="right">{t("size")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attachments.map((attachment) => {
                      return (
                        <TableRow key={attachment.id}>
                          <TableCell component="td" scope="row">
                            <Grid container alignItems="center">
                              <Grid item>
                                <Link
                                  href={getClaimAttachmentUrl(attachment)}
                                  target="_blank"
                                >
                                  {attachment.name}
                                </Link>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align="right">
                            {humanizeBytes(attachment.size)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item sm={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      readOnly={readOnly}
                      checked={invoiceByLetterPost ?? false}
                      onChange={(event) =>
                        setInvoiceByLetterPost(event.target.checked)
                      }
                      name="invoiceByLetterPost"
                      color="primary"
                      disabled={readOnly}
                    />
                  }
                  label={t("step6.sendByAnalog")}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </AccordionDetails>
        {!readOnly && (
          <>
            <Divider />
            <AccordionActions>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => closeClaim()}
              >
                {t("step6.closeOrder")}
              </Button>
            </AccordionActions>
          </>
        )}
      </Accordion>
    </>
  );
};

export default Step06InvoiceAndClosing;
