import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Badge,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { getClaims } from "api/api.jobs";
import Search from "./search";
import { useSimplux } from "@simplux/react";
import { claims } from "state/claims";
import ClaimList from "./claim_list";
import useDebounce from "core/react_utils";
import CraftsmanSelection from "./craftsman_selection";
import { appState } from "state/app_state";
import { useTranslation } from "react-i18next";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const ClaimMaster = () => {
  const claimsInProgress = useSimplux(claims.claimsInProgress);
  const claimRequests = useSimplux(claims.claimRequests);
  const claimsArchived = useSimplux(claims.claimsArchived);
  const tabIndex = useSimplux(claims.tabIndex);

  const fuzzySearchValue = useSimplux(claims.fuzzySearch);

  const [backdrop] = useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "grid",
        gridTemplateRows: "auto auto 1fr",
        height: "100vh",
      },
      header: {
        gridRow: 1,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(2),
      },
      tabs: {
        gridRow: 2,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      content: {
        gridRow: 3,
        overflowX: "hidden",
        overflowY: "auto",
      },
      title: {
        marginBottom: theme.spacing(1),
      },
      tabRoot: {
        minWidth: 0,
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
      verticalMargin: {
        marginTop: theme.spacing(3),
      },
    })
  );

  const classes = useStyles();

  const isCaseHandler = useSimplux(appState.isCaseHandler);

  const debouncedSearchTerm = useDebounce(fuzzySearchValue, 350);

  useEffect(() => {
    getClaims(debouncedSearchTerm)
      .then((response) => claims.updateClaims(response.results))
      .catch(() => claims.updateClaims([]));
  }, [debouncedSearchTerm]);

  const { t } = useTranslation("translation", { useSuspense: false });

  return (
    <>
      <Backdrop open={backdrop} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <div className={classes.root}>
        <div className={classes.header}>
          <Grid container>
            <Grid
              item
              xs={12}
              className={classes.title}
              container
              justify="space-between"
            >
              <Grid item>
                <h1>{t("overview.title")}</h1>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Search />
            </Grid>
            {isCaseHandler && (
              <Grid item xs={12} className={classes.verticalMargin}>
                <CraftsmanSelection />
              </Grid>
            )}
          </Grid>
        </div>
        <Tabs
          value={tabIndex ?? 0}
          onChange={(_e, newTab) => claims.setTabIndex(newTab)}
          variant="fullWidth"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabs}
        >
          <Tab
            label={
              <Badge color="primary" badgeContent={claimRequests.length}>
                {t("overview.anfragen")}
              </Badge>
            }
            classes={{ root: classes.tabRoot }}
          />
          <Tab
            label={t("overview.angenommen")}
            classes={{ root: classes.tabRoot }}
          />
          <Tab
            label={t("overview.abgeschlossen")}
            classes={{ root: classes.tabRoot }}
          />
        </Tabs>
        <div className={classes.content}>
          <TabPanel value={tabIndex ?? 0} index={0}>
            <ClaimList
              claims={claimRequests}
              emptyText={t("overview.noClaims")}
            />
          </TabPanel>
          <TabPanel value={tabIndex ?? 0} index={1}>
            <ClaimList
              claims={claimsInProgress}
              emptyText={t("overview.noClaims")}
            />
          </TabPanel>
          <TabPanel value={tabIndex ?? 0} index={2}>
            <ClaimList
              claims={claimsArchived}
              emptyText={t("overview.noClosedClaims")}
            />
          </TabPanel>
        </div>
      </div>
    </>
  );
};

export default ClaimMaster;

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
