import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GvbColors from "core/GvbColors";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { IconButton } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { getCraftsmanLanguage, setCraftsmanLanguage } from "api/api.craftsmen";
import { useSimplux } from "@simplux/react";
import { appState } from "state/app_state";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: GvbColors.fontDark,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
  },
  title: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(28),
    textTransform: "uppercase",
  },
  languageSelect: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  languageButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    color: "white",
    fontWeight: 600,
    marginLeft: "0.5rem",
    "&:focus": {
      outline: "none",
    },
  },
  activeLanguage: {
    textDecoration: "underline",
  },
}));

function Header() {
  const classes = useStyles();
  const { t, i18n } = useTranslation("translation", { useSuspense: false });
  const { instance } = useMsal();
  const isAuthenticated = useSimplux(appState.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchLanguage = async () => {
        let craftsmanLanguage = await getCraftsmanLanguage();
        if (!!craftsmanLanguage) {
          i18n.changeLanguage(craftsmanLanguage);
        }
      };

      fetchLanguage();
    }
  }, [isAuthenticated, i18n]);

  const onLogout = async () => {
    instance.logoutRedirect({ postLogoutRedirectUri: window.location.origin });
  };

  return (
    <div className={classes.header}>
      <div className={classes.title}>
        <strong>GVB</strong> {t("handwerkerportal")}
      </div>
      <div className={classes.languageSelect}>
        <button
          className={`${classes.languageButton} ${
            i18n.language === "de" ? classes.activeLanguage : ""
          }`}
          onClick={async () => {
            i18n.changeLanguage("de");
            await setCraftsmanLanguage("de");
          }}
        >
          DE
        </button>
        <button
          className={`${classes.languageButton} ${
            i18n.language === "fr" ? classes.activeLanguage : ""
          }`}
          onClick={async () => {
            i18n.changeLanguage("fr");
            await setCraftsmanLanguage("fr");
          }}
        >
          FR
        </button>
      </div>
      <div style={{ display: "flex" }}>
        <IconButton onClick={onLogout}>
          <ExitToAppIcon style={{ color: "white" }} />
        </IconButton>
      </div>
    </div>
  );
}

export default Header;
