import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { appState } from "state/app_state";
import { getCraftsman } from "api/api.craftsmen";
import { claims } from "state/claims";
import { getClaims } from "api/api.jobs";
import {
  makeStyles,
  Theme,
  createStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { CaseHandlerDeepLinkParams } from "./case_handler_deep_link_params";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

const CaseHandlerEntry = () => {
  const classes = useStyles();

  const { craftsmanId } = useParams<CaseHandlerDeepLinkParams>();
  const history = useHistory();

  useEffect(() => {
    async function async() {
      let craftsman = await getCraftsman(craftsmanId);
      appState.setCraftsman(craftsman);
      claims.setLoadingClaims(true);
      claims.updateClaims((await getClaims("")).results);
      history.push("/");
    }

    if (craftsmanId) {
      async();
    }
  });

  return (
    <Backdrop open={true} className={classes.backdrop}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default CaseHandlerEntry;
