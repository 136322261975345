export function removeCollectionPrefix(s: string) {
  if (!s) {
    return s;
  }

  let index = s.indexOf("/");

  if (index === -1) {
    return s;
  }

  return s.substring(s.indexOf("/") + 1);
}
