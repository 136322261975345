import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import ClaimMaster from "./master";
import ClaimDetail from "./detail";

const Claims = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      wrapper: {
        display: "grid",
        gridTemplateColumns: "380px auto",
        gridGap: "8px",
        height: "100vh",
      },
      claimMaster: {
        gridColumn: "1",
        backgroundColor: theme.palette.background.paper,
        borderRight: "1px solid " + theme.palette.divider,
      },
      claimDetail: {
        gridColumn: "2",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: "100vh",
        overflowX: "auto",
        overflowY: "auto",
      },
    })
  );

  const classes = useStyles();

  return (
    <>
      <div id="app-layout" className={classes.wrapper}>
        <div className={classes.claimMaster}>
          <ClaimMaster />
        </div>
        <div className={classes.claimDetail}>
          <ClaimDetail />
        </div>
      </div>
    </>
  );
};

export default Claims;
