import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import translationDE from "locales/de/translation.json";
import translationFR from "locales/fr/translation.json";
import { getApiUrl } from "core/api_client_factory";
import LanguageDetector from "i18next-browser-languagedetector";
import { version } from "version";

const resources = {
  de: {
    translation: translationDE,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(LanguageDetector)
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "de",
    debug: true,

    preload: ["de", "fr"],
    ns: ["causes"],

    backend: {
      backends: [resourcesToBackend(resources), HttpBackend],
      backendOptions: [
        {},
        {
          loadPath: getApiUrl() + `/locales/{{lng}}/{{ns}}.json?v=${version}`,
          crossDomain: true,
        },
      ],
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    detection: {
      order: ["navigator", "htmlTag"],

      caches: [],

      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,
    },
  });

export default i18n;
