import React from "react";
import { Switch, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Login from "./pages/auth/login";
import { appState } from "state/app_state";
import { useSimplux } from "@simplux/react";
import Claims from "pages/claims/claims";
import CaseHandlerEntry from "pages/deep-links/case_handler_entry";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import useMsalEvents from "pages/auth/use_msal_events";
import ModalCircularProgress from "components/modal_circular_progress";
import Header from "header";
import CookieBot from "react-cookiebot";

function App() {
  const isInitialized = useSimplux(appState.isInitialized);
  useMsalEvents();

  return (
    <>
      <CookieBot domainGroupId="9c21eff0-3b13-4fa6-927a-50ca8f3bd99d" />
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Header />
        <BrowserRouter>
          <ModalCircularProgress open={!isInitialized} />
          {isInitialized && (
            <Switch>
              <Route path="/" exact render={() => <Claims />} />
              <Route path="/claims/:claimId?" exact render={() => <Claims />} />
              <Route path="/jobs/:claimId?" exact render={() => <Claims />} />
              <Route
                path="/s/:craftsmanId"
                exact
                render={() => <CaseHandlerEntry />}
              />
            </Switch>
          )}
        </BrowserRouter>
      </AuthenticatedTemplate>
    </>
  );
}

export default App;
