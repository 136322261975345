import {
  createSimpluxModule,
  createMutations,
  createSelectors,
} from "@simplux/core";
import { ClaimListDto, ClaimDto, CraftsmanJobState } from "api/dtos";
import { appState } from "./app_state";

const claimsModule = createSimpluxModule({
  name: "claims",
  initialState: {
    expandedStep: false as string | boolean,
    selectedClaim: null,
    loadingDetailClaim: false,
    detailClaim: null as ClaimDto,
    fuzzySearch: "",
    claimsInProgress: [] as ClaimListDto[],
    claimRequests: [] as ClaimListDto[],
    claimsArchived: [] as ClaimListDto[],
    loadingClaims: false,
    tabIndex: 1,
  },
});

export const claims = {
  ...claimsModule,
  ...createMutations(claimsModule, {
    toggleExpandedStep(state, expandedStep: string | boolean) {
      if (state.expandedStep === expandedStep) {
        state.expandedStep = false;
      } else {
        state.expandedStep = expandedStep;
      }
    },
    setExpandedStep(state, expandedStep: string | boolean) {
      state.expandedStep = expandedStep;
    },
    setSelectedClaim(state, claimId: string) {
      if (state.selectedClaim?.id !== claimId) {
        let claim = state.claimsInProgress.find((p) => p.id === claimId);
        state.selectedClaim = claim;
        state.expandedStep = false;
        state.loadingDetailClaim = true;
        state.detailClaim = null;
      }
    },
    setDetailClaim(state, claim: ClaimDto) {
      state.detailClaim = claim;
      state.loadingDetailClaim = false;
    },
    setFuzzySearch(state, newFuzzySearch: string) {
      state.fuzzySearch = newFuzzySearch;
      state.loadingClaims = true;
    },
    updateClaims(state, claims: ClaimListDto[]) {
      state.claimRequests = claims.filter(
        (p) => p.craftsmanJob.state === "Requested"
      );
      state.claimsArchived = claims.filter(
        (p) => p.craftsmanJob.state === "Closed"
      );
      state.claimsInProgress = claims.filter(
        (p) =>
          p.craftsmanJob.state !== "Requested" &&
          p.craftsmanJob.state !== "Closed" &&
          p.craftsmanJob.state !== "Declined"
      );

      state.loadingClaims = false;
    },
    setLoadingClaims(state, isLoading: boolean) {
      state.loadingClaims = isLoading;
    },
    setTabIndex(state, tabIndex: number) {
      state.tabIndex = tabIndex;
    },
    acceptClaim(state, claim: ClaimDto) {
      let now = new Date().toISOString();
      let requestItem = state.claimRequests.find((p) => p.id === claim.id);
      requestItem.craftsmanJob.state = CraftsmanJobState.Accepted;
      requestItem.craftsmanJob.acceptedAt = now;
      state.detailClaim.craftsmanJob.state = CraftsmanJobState.Accepted;
      state.detailClaim.craftsmanJob.acceptedAt = now;

      state.claimRequests = state.claimRequests.filter(
        (p) => p !== requestItem
      );
      state.claimsInProgress.unshift(requestItem);
      state.tabIndex = 1;
    },
    declineClaim(state, claim: ClaimDto) {
      let requestItem = state.claimRequests.find((p) => p.id === claim.id);
      state.claimRequests = state.claimRequests.filter(
        (p) => p !== requestItem
      );
      state.detailClaim = undefined;
    },
    setInspectionDate(state, date: string) {
      if (date === "") {
        state.detailClaim.craftsmanJob.appointment = null;
      } else {
        state.detailClaim.craftsmanJob.appointment = date;
      }

      state.detailClaim.craftsmanJob.state =
        CraftsmanJobState.AppointmentEntered;
      state.detailClaim.craftsmanJob.appointmentEnteredAt =
        new Date().toISOString();

      state.expandedStep = "step02";
    },
    setQuote(state, price: number, description: string) {
      state.detailClaim.craftsmanJob.price = price;
      state.detailClaim.craftsmanJob.quoteEnteredAt = new Date().toUTCString();
      state.detailClaim.craftsmanJob.state = CraftsmanJobState.QuoteEntered;

      state.expandedStep = "step03";
    },
    setFixingAt(state, date: string) {
      state.detailClaim.craftsmanJob.fixingAt = date;
      state.detailClaim.craftsmanJob.state = CraftsmanJobState.Fixing;
      state.detailClaim.craftsmanJob.fixingAtEnteredAt =
        new Date().toUTCString();

      state.expandedStep = "step05";
    },
    setFixed(state) {
      let now = new Date().toUTCString();
      state.detailClaim.craftsmanJob.fixedAt = now;
      state.detailClaim.craftsmanJob.state = CraftsmanJobState.Fixed;

      state.expandedStep = "step06";
    },
    setClosed(state) {
      let claimId = state.detailClaim.id;

      let claimListItem = state.claimsInProgress.find((p) => p.id === claimId);
      state.claimsInProgress = state.claimsInProgress.filter(
        (p) => p !== claimListItem
      );
      state.claimsArchived.unshift(claimListItem);

      state.detailClaim.craftsmanJob.state = CraftsmanJobState.Closed;
      state.expandedStep = false;
    },
    clear(state) {
      state.detailClaim = undefined;
      state.selectedClaim = undefined;
      state.claimRequests = [];
      state.claimsArchived = [];
      state.claimsInProgress = [];
    },
  }),
  ...createSelectors(claimsModule, {
    expandedStep: ({ expandedStep }) => expandedStep,
    selectedClaimListItem: ({ selectedClaim }) => selectedClaim,
    selectedClaimId: ({ selectedClaim }) => selectedClaim?.id,
    detailClaim: ({ detailClaim }) => detailClaim as ClaimDto,
    loadingDetailClaim: ({ loadingDetailClaim }) => loadingDetailClaim,
    fuzzySearch: ({ fuzzySearch }) => fuzzySearch,
    claimsInProgress: ({ claimsInProgress }) =>
      claimsInProgress as ClaimListDto[],
    claimRequests: ({ claimRequests }) => claimRequests as ClaimListDto[],
    claimsArchived: ({ claimsArchived }) => claimsArchived as ClaimListDto[],
    loadingClaims: ({ loadingClaims }) => loadingClaims,
    tabIndex: ({ tabIndex }) => tabIndex,
  }),
};

appState.subscribeToStateChanges(
  ({ craftsman }, prevousState) => {
    if (!craftsman) {
      claims.clear();
    }
  },
  { shouldSkipInitialInvocation: true }
);
