import { CustomerType } from "api/dtos";

export function humanizeCustomerType(type: CustomerType) {
  switch (type) {
    case "Administration":
      return "Verwaltung";
    case "Broker":
      return "Broker";
    case "Other":
      return "Andere";
    case "Owner":
      return "Eigentümer";

    default:
      return "";
  }
}
