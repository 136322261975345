import {
  createApiClient,
  createApiClientWithToken,
} from "core/api_client_factory";
import {
  Authenticate,
  AuthenticateResponse,
  ConvertTokenToCookie,
} from "api/dtos";

export function checkLogin() {
  let client = createApiClient();
  let request = new Authenticate();

  return client.get(request);
}

let getSessionFromTokenPromise: Promise<void>;

export function getSessionFromToken(token: string) {
  if (getSessionFromTokenPromise === undefined) {
    const client = createApiClientWithToken(token);
    const request = new ConvertTokenToCookie();

    getSessionFromTokenPromise = client.get(request);
  }
  return getSessionFromTokenPromise;
}

export function tokenCookiePresent(): Promise<
  [boolean, AuthenticateResponse | null]
> {
  const client = createApiClient();
  const request = new Authenticate();

  return client
    .get(request)
    .then((r) => [true, r] as [boolean, AuthenticateResponse | null])
    .catch(() => [false, null]);
}
