import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Divider,
  AccordionActions,
  Button,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useSimplux } from "@simplux/react";
import { claims } from "state/claims";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStepStyles from "./step_styles";
import { ClaimDto } from "api/dtos";
import {
  isValidDate,
  iso8601ToDate,
  humanizeIso8601ToDateTime,
} from "core/date_utils";
import { updateFixingAt } from "api/api.claims";
import { useTranslation } from "react-i18next";

const stepName = "step04";

const Step04RepairDate = () => {
  const classes = useStepStyles();

  const expandedStep = useSimplux(claims.expandedStep);
  const claim = useSimplux(claims.detailClaim) as ClaimDto;

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    iso8601ToDate(claim.craftsmanJob.fixingAt)
  );
  const [backdrop, setBackdrop] = useState(false);
  const [showError, setShowError] = useState(false);

  const readOnly = claim.craftsmanJob.state !== "JobApproved";

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const save = async () => {
    if (!isValidDate(selectedDate)) {
      setShowError(true);
      return;
    }

    try {
      setBackdrop(true);

      let dateAsIso8601 = selectedDate.toISOString();

      await updateFixingAt(claim.id, dateAsIso8601);
      setShowError(false);
      claims.setFixingAt(dateAsIso8601);
    } finally {
      setBackdrop(false);
    }
  };

  const { t } = useTranslation("translation", { useSuspense: false });

  return (
    <>
      <Backdrop open={backdrop} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Accordion
        expanded={expandedStep === stepName}
        onChange={() => claims.toggleExpandedStep(stepName)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${stepName}-content`}
          id={`${stepName}bh-header`}
        >
          <Typography className={classes.heading}>
            {t("step4.title")}
          </Typography>
          {claim.craftsmanJob.fixingAt && (
            <Typography className={classes.secondaryHeading}>
              {humanizeIso8601ToDateTime(claim.craftsmanJob.fixingAt)}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item sm={12}>
              <Typography>{t("step4.description")}</Typography>
            </Grid>
            <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    label={t("datumUhrzeit")}
                    value={selectedDate}
                    onChange={handleDateChange}
                    invalidDateMessage={t("invalidDateMessage")}
                    disabled={readOnly}
                    format="dd.MM.yyyy HH:mm"
                    error={showError}
                    helperText={showError ? t("pleaseFillOut") : ""}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </AccordionDetails>
        {!readOnly && (
          <>
            <Divider />
            <AccordionActions>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => save()}
              >
                {t("eintragen")}
              </Button>
            </AccordionActions>
          </>
        )}
      </Accordion>
    </>
  );
};

export default Step04RepairDate;
