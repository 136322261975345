import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import GvbColors from "core/GvbColors";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfiguration } from "pages/auth/msal_configuration";
import "i18n";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: GvbColors.oxidRed1,
    },
    divider: GvbColors.frameStroke,
    background: {
      paper: GvbColors.paperBackground,
      default: GvbColors.defaultBackground,
    },
  },
  shape: {},
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 8,
      },
    },
    MuiTypography: {
      subtitle1: {
        color: GvbColors.subtext,
      },
    },
  },
});

const msalInstance = new PublicClientApplication(msalConfiguration);
ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
