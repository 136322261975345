import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CraftsmanDto } from "api/dtos";
import { getCraftsmen } from "api/api.craftsmen";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { appState } from "state/app_state";
import { getClaims } from "api/api.jobs";
import { claims } from "state/claims";
import { useHistory } from "react-router-dom";
import { useSimplux } from "@simplux/react";
import { useTranslation } from "react-i18next";

const companyRegex = /(\(.*Betrieb.*\))\s*/i;

const CraftsmanSelection = () => {
  const history = useHistory();

  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<CraftsmanDto[]>([]);
  const [loading, setLoading] = useState(false);

  const selectedCraftsman = useSimplux(appState.craftsman);

  const { t } = useTranslation("translation", { useSuspense: false });

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      const response = await getCraftsmen(search);

      if (active) {
        setLoading(false);
        setOptions(response.results);
      }
    })();

    return () => {
      active = false;
    };
  }, [search]);

  const neutralizeCompany = (company: string) => {
    return company.replace(companyRegex, "");
  };

  const onCraftsmanChanged = (craftsman: CraftsmanDto | null) => {
    appState.setCraftsman(craftsman ?? false);

    if (craftsman) {
      claims.setLoadingClaims(true);

      getClaims(search).then((response) => {
        claims.updateClaims(response.results);
      });
    } else {
      history.push("/");
    }
  };

  return (
    <Autocomplete
      id="craftsman-selection"
      open={open}
      value={(selectedCraftsman as CraftsmanDto) ?? null}
      onChange={(_event: any, craftsman: CraftsmanDto | null) =>
        onCraftsmanChanged(craftsman)
      }
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) => option?.company ?? ""}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("craftsman")}
          variant="outlined"
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(option) => {
        const abandoned = companyRegex.test(option.company);

        return (
          <>
            <Grid container alignItems="center">
              <Grid item xs>
                <span
                  style={{
                    fontWeight: 700,
                    textDecoration: abandoned ? "line-through" : "none",
                  }}
                >
                  {neutralizeCompany(option.company)}
                </span>
                <Typography variant="body2" color="textSecondary">
                  {option.street}, {option.postalCode} {option.place}
                </Typography>
              </Grid>
            </Grid>
          </>
        );
      }}
    />
  );
};

export default CraftsmanSelection;
