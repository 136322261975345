import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
} from "@material-ui/core";
import { useSimplux } from "@simplux/react";
import { claims } from "state/claims";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStepStyles from "./step_styles";
import { ClaimDto } from "api/dtos";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const stepName = "step03";

const Step03Approval = () => {
  const classes = useStepStyles();
  const expandedStep = useSimplux(claims.expandedStep);

  const claim = useSimplux(claims.detailClaim) as ClaimDto;

  const { t } = useTranslation("translation", { useSuspense: false });

  return (
    <>
      <Accordion
        expanded={expandedStep === stepName}
        onChange={() => claims.toggleExpandedStep(stepName)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${stepName}bh-content`}
          id={`${stepName}bh-header`}
        >
          <Typography className={classes.heading}>
            {t("step3.title")}
          </Typography>
          {!claim.craftsmanJob.approvedAt &&
            claim.craftsmanJob.state === "QuoteEntered" && (
              <Typography className={classes.secondaryHeading}>
                {t("step3.waitingApproval")}
              </Typography>
            )}
          {claim.craftsmanJob.approvedAt && (
            <Typography className={classes.secondaryHeading}>
              {t("confirmed")}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item sm={12}>
              {!claim.craftsmanJob.approvedAt && (
                <Alert color="info">{t("step3.waitingText")}</Alert>
              )}
              {claim.craftsmanJob.approvedAt && (
                <Alert color="success">{t("step3.confirmText")}</Alert>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Step03Approval;
