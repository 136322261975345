import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { useSimplux } from "@simplux/react";
import { appState } from "state/app_state";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const NoClaimSelected = () => {
  const isCaseHandler = useSimplux(appState.isCaseHandler);
  const craftsman = useSimplux(appState.craftsman);
  const { t } = useTranslation("translation", { useSuspense: false });

  return (
    <>
      <Grid
        container
        direction="column"
        style={{ minHeight: "100vh" }}
        justify="center"
        alignContent="center"
      >
        <Grid item>
          <Typography variant="h5">{t("overview.emptyTitle")}</Typography>
        </Grid>
        <Grid item>
          <Typography>{t("overview.emptyText")}</Typography>
        </Grid>
        {isCaseHandler && !craftsman && (
          <Grid item>
            <Box mt={4}>
              <Alert severity="info">
                Als <strong>Sachbearbeiter</strong> müssen Sie einen Handwerker
                auswählen um Fälle zu sehen.
              </Alert>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default NoClaimSelected;
