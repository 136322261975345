import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Backdrop,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { useSimplux } from "@simplux/react";
import { claims } from "state/claims";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStepStyles from "./step_styles";
import { ClaimDto } from "api/dtos";
import { humanizeIso8601ToDateTime } from "core/date_utils";
import { updateFixedAt } from "api/api.claims";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

const stepName = "step05";

const Step05FinishedWork = () => {
  const classes = useStepStyles();
  const expandedStep = useSimplux(claims.expandedStep);
  const claim = useSimplux(claims.detailClaim) as ClaimDto;
  const [backdrop, setBackdrop] = useState(false);

  const readOnly = claim.craftsmanJob.state !== "Fixing";

  const setFixed = async () => {
    try {
      setBackdrop(true);
      await updateFixedAt(claim.id);
      claims.setFixed();
    } finally {
      setBackdrop(false);
    }
  };

  const { t } = useTranslation("translation", { useSuspense: false });

  return (
    <>
      <Backdrop open={backdrop} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Accordion
        expanded={expandedStep === stepName}
        onChange={() => claims.toggleExpandedStep(stepName)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${stepName}bh-content`}
          id={`${stepName}bh-header`}
        >
          <Typography className={classes.heading}>
            {t("step5.title")}
          </Typography>
          {claim.craftsmanJob.fixedAt && (
            <Typography className={classes.secondaryHeading}>
              {t("step5.confirmedAt", {
                date: humanizeIso8601ToDateTime(claim.craftsmanJob.fixedAt),
              })}
            </Typography>
          )}
          {!claim.craftsmanJob.fixedAt && (
            <Typography className={classes.secondaryHeading}></Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Typography>{t("step5.description")}</Typography>
            </Grid>
            {!claim.craftsmanJob.fixedAt && (
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setFixed()}
                  disabled={readOnly}
                >
                  {t("confirm")}
                </Button>
              </Grid>
            )}
            {claim.craftsmanJob.fixedAt && (
              <Grid item>
                <Alert severity="success">
                  {t("step5.confirmedAt", {
                    date: humanizeIso8601ToDateTime(claim.craftsmanJob.fixedAt),
                  })}
                </Alert>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Step05FinishedWork;
