import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { claims } from "state/claims";
import { useSimplux } from "@simplux/react";
import GvbColors from "core/GvbColors";
import { appState } from "state/app_state";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      backgroundColor: GvbColors.selected,
      height: theme.spacing(5),
      borderRadius: theme.shape.borderRadius,
    },
    input: {
      flex: 1,
    },
  })
);

const Search = () => {
  const classes = useStyles();

  const fuzzySearchValue = useSimplux(claims.fuzzySearch);
  const canQueryClaims = useSimplux(appState.canQueryClaims);

  const [key, setKey] = useState(1);

  return (
    <div className={classes.root}>
      <IconButton>
        <SearchIcon />
      </IconButton>
      <InputBase
        key={key}
        className={classes.input}
        placeholder="Suche..."
        defaultValue={fuzzySearchValue ?? ""}
        onChange={(event) => claims.setFuzzySearch(event.target.value)}
        disabled={!canQueryClaims}
      />
      {fuzzySearchValue ? (
        <IconButton
          onClick={() => {
            claims.setFuzzySearch("");
            setKey(key + 1);
          }}
        >
          <ClearIcon />
        </IconButton>
      ) : (
        ""
      )}
    </div>
  );
};

export default Search;
