import {
  AuthenticationResult,
  EventMessage,
  EventType,
  SilentRequest,
} from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { getSessionFromToken, tokenCookiePresent } from "api/api.user";
import { useEffect } from "react";
import { appState } from "state/app_state";
import { OidcConfiguration } from "./msal_configuration";

const useMsalEvents = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      tokenCookiePresent().then(async ([present, profile]) => {
        if (!present) {
          const account = instance.getAllAccounts()[0];
          const request: SilentRequest = {
            account: account,
            scopes: OidcConfiguration.clientScope,
          };
          const response = await instance.acquireTokenSilent(request);
          await getSessionFromToken(response.accessToken);
          const [, profile] = await tokenCookiePresent();
          appState.setAuthenticated(profile.userName, profile.roles);
        } else {
          appState.setAuthenticated(profile.userName, profile.roles);
        }
      });
    }
  }, [isAuthenticated, instance]);

  useEffect(() => {
    const callbackId = instance.addEventCallback(
      async (event: EventMessage) => {
        if (
          event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS
        ) {
          const response = event.payload as AuthenticationResult;
          instance.setActiveAccount(response.account);
          await getSessionFromToken(response.accessToken);
          const [, profile] = await tokenCookiePresent();

          appState.setAuthenticated(profile.userName, profile.roles);
        }

        if (event.eventType === EventType.HANDLE_REDIRECT_END) {
          if (!isAuthenticated) {
            let [present, profile] = await tokenCookiePresent();
            if (!present) {
              const account = instance.getActiveAccount();
              const silentResponse = await instance.acquireTokenSilent({
                account,
                scopes: OidcConfiguration.clientScope,
              });
              if (silentResponse && silentResponse.accessToken) {
                await getSessionFromToken(silentResponse.accessToken);
                [, profile] = await tokenCookiePresent();
              }
            }
            appState.setAuthenticated(profile.userName, profile.roles);
          }
        }
      }
    );

    return () => instance.removeEventCallback(callbackId);
  }, [instance, accounts, isAuthenticated]);
};
export default useMsalEvents;
