import { createApiClient, getApiUrl } from "core/api_client_factory";
import {
  GetClaim,
  GetClaimAttachments,
  ClaimAttachmentDto,
  CraftsmanJobState,
  UpdateCraftsmanJobState,
  UpdateCraftsmanJobAppointment,
  UploadClaimAttachment,
  ClaimAttachmentType,
  CreateQuote,
  UpdateFixingAt,
  UpdateFixedAt,
  UpdateClosedAt,
  IdentityType,
} from "api/dtos";
import { removeCollectionPrefix } from "core/raven_utils";

export function getClaim(id: string) {
  let client = createApiClient();
  let request = new GetClaim();
  request.claimId = id;

  return client.get(request);
}

export function getClaimAttachments(id: string) {
  let client = createApiClient();
  let request = new GetClaimAttachments({
    claimId: removeCollectionPrefix(id),
  });

  return client.get(request);
}

export function getClaimAttachmentsWithType(
  id: string,
  type: ClaimAttachmentType
) {
  let client = createApiClient();
  let request = new GetClaimAttachments({
    claimId: removeCollectionPrefix(id),
  });
  request.type = type;

  return client.get(request);
}

export function getClaimAttachmentUrl(
  attachment: ClaimAttachmentDto,
  preview: boolean = false,
  inline: boolean = true
): string {
  if (!attachment) {
    return null;
  }

  let claimAttachmentId = removeCollectionPrefix(attachment.id);

  return `${getApiUrl()}/claim-attachments/${claimAttachmentId}?inline=${inline}&preview=${preview}`;
}

export function updateCraftsmanJobState(
  id: string,
  newState: CraftsmanJobState
): Promise<void> {
  let request = new UpdateCraftsmanJobState();
  request.id = removeCollectionPrefix(id);
  request.state = newState;

  let client = createApiClient();

  return client.put(request);
}

export function updateCraftsmanJobAppointment(claimId: string, date: string) {
  let request = new UpdateCraftsmanJobAppointment();
  request.id = removeCollectionPrefix(claimId);
  request.appointment = date;

  let client = createApiClient();

  return client.put(request);
}

export async function uploadCraftsmanJobAttachment(
  claimId: string,
  file: File,
  type: ClaimAttachmentType
) {
  var formData = new FormData();
  formData.append("file", file);

  let request = new UploadClaimAttachment({});
  request.claimId = removeCollectionPrefix(claimId);
  request.type = type;
  request.name = file.name;
  request.assignedType = IdentityType.Craftsman;

  let client = createApiClient();
  return client.postBody(request, formData);
}

export function submitQuote(
  claimId: string,
  price: number,
  description: string
) {
  let request = new CreateQuote();
  request.claimId = removeCollectionPrefix(claimId);
  request.price = price;
  request.description = description;

  let client = createApiClient();

  return client.post(request);
}

export function updateFixingAt(claimId: string, date: string) {
  let request = new UpdateFixingAt();
  request.claimId = removeCollectionPrefix(claimId);
  request.jobFixingAt = date;

  let client = createApiClient();

  return client.put(request);
}

export function updateFixedAt(claimId: string) {
  let request = new UpdateFixedAt();
  request.claimId = removeCollectionPrefix(claimId);
  request.jobFixedAt = new Date().toISOString();

  let client = createApiClient();

  return client.put(request);
}

export function updateClosedAt(claimId: string, invoiceByLetterPost: boolean) {
  let request = new UpdateClosedAt();

  request.claimId = removeCollectionPrefix(claimId);
  request.jobClosedAt = new Date().toISOString();
  request.invoiceByLetterPost = invoiceByLetterPost;

  let client = createApiClient();

  return client.put(request);
}
