import React from "react";
import { useSimplux } from "@simplux/react";
import { claims } from "state/claims";
import {
  Grid,
  CircularProgress,
  Typography,
  List,
  Box,
} from "@material-ui/core";
import { ClaimListDto } from "api/dtos";
import ClaimListItem from "./claim_list_item";
import { removeCollectionPrefix } from "core/raven_utils";
import { useParams } from "react-router-dom";
import { ClaimDetailUrlParams } from "./claim_detail_url_params";
import { contractAddressDisplay } from "core/claim";

type ClaimListPropTypes = {
  claims: ClaimListDto[];
  emptyText: string;
};

const ClaimList = (props: ClaimListPropTypes) => {
  const loading = useSimplux(claims.loadingClaims);
  const { claimId } = useParams<ClaimDetailUrlParams>();

  return (
    <>
      {loading ? (
        <Grid
          style={{ height: "100%" }}
          container
          direction="column"
          justify="center"
          alignContent="center"
        >
          <CircularProgress />
        </Grid>
      ) : props.claims.length === 0 ? (
        <Grid
          style={{ height: "100%" }}
          container
          direction="column"
          justify="center"
          alignContent="center"
          spacing={4}
        >
          <Grid item>
            <Box mx={4}>
              <Typography variant="h5">{props.emptyText}</Typography>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <List>
          {props.claims.map((claim) => (
            <ClaimListItem
              key={claim.id}
              claimId={claim.id}
              claimSapId={claim.claimSapId}
              name={`${claim.firstName} ${claim.lastName}`}
              address={contractAddressDisplay(claim.contract)}
              claimDate={claim.craftsmanJob.requestedAt}
              isSelected={
                claimId === removeCollectionPrefix(claim.id).toLowerCase()
              }
            />
          ))}
        </List>
      )}
    </>
  );
};

export default ClaimList;
