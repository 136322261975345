import moment from "moment";

const DATE_FORMAT = "DD.MM.YYYY";
const DATETIME_FORMAT = "DD.MM.YYYY HH:mm";

export function humanizeIso8601ToDate(str: string) {
  return humanizeIso8601(str, DATE_FORMAT);
}

export function humanizeIso8601ToDateTime(str: string) {
  return humanizeIso8601(str, DATETIME_FORMAT);
}

function humanizeIso8601(str: string, format: string) {
  if (!str) {
    return str;
  }

  let m = moment(str);

  if (m.isValid()) {
    return m.format(format);
  }

  return str;
}

export function iso8601ToDate(str: string) {
  if (str) {
    return new Date(str);
  }

  return null; // important to use null here instead of undefined since the datepicker component interprets null as empty and undefined as new Date()
}

export function isValidDate(date) {
  return moment(date).isValid();
}
