// colors from figma
// const oxidRed2 = '#873930';
// const orange1 = '#da8b31';
// const orange2 = '#e59b39';
// const red = '#d43d2f';
// const background = '#e5e5e6';
// const search = '#69347a';
const GvbColors = {
  selected: "#F3F3F3",
  oxidRed1: "#91332b",
  defaultBackground: "#F8F8F8",
  paperBackground: "white",
  frameStroke: "#F0F0F0",
  subtext: "#6E6E6E",
  fontDark: "#212121",
};

export default GvbColors;
