import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  FormLabel,
  Grid,
  GridList,
  GridListTile,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import { useSimplux } from "@simplux/react";
import {
  getClaimAttachments,
  getClaimAttachmentUrl,
  updateCraftsmanJobState,
} from "api/api.claims";
import { ClaimAttachmentDto, CraftsmanJobState, IdentityDto } from "api/dtos";
import { contractAddressDisplay } from "core/claim";
import { humanizeBytes } from "core/conversion_utils";
import { humanizeIso8601ToDate } from "core/date_utils";
import { humanizeCustomerType } from "core/enums";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import sanitizeHtml from "sanitize-html";
import { claims } from "state/claims";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
      marginLeft: 14,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    title: {
      marginBottom: 0,
    },
    ul: {
      marginTop: 0,
      paddingInlineStart: theme.spacing(2) + "px", // TODO: talk about this fist!
    },
    label: {
      // TODO: didn't find a way to customize FormLabel
      marginBottom: theme.spacing(2) + "px",
    },
    icon: {
      display: "inline-flex",
      verticalAlign: "middle",
    },
  })
);

const ClaimInfos = () => {
  const claim = useSimplux(claims.detailClaim);

  const [attachments, setAttachments] = useState<ClaimAttachmentDto[]>([]);
  const [loadingAttachments, setLoadingAttachments] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  const cause = claim.triageDecision.cause
    ? claim.triageDecision.cause.descriptionCode
    : "-";

  const classes = useStyles();

  const damagePhotos = attachments.filter((p) => p.type === "DamagePhoto");
  const otherAttachments = attachments.filter((p) => p.type !== "DamagePhoto");

  useEffect(() => {
    async function loadAttachments() {
      setLoadingAttachments(true);
      let response = await getClaimAttachments(claim.id);

      setAttachments(response.claimAttachments);
      setLoadingAttachments(false);
    }

    loadAttachments();
  }, [claim.id]);

  const accept = async (accepted: boolean) => {
    try {
      setBackdrop(true);
      await updateCraftsmanJobState(
        claim.id,
        accepted ? CraftsmanJobState.Accepted : CraftsmanJobState.Declined
      );

      if (accepted) {
        claims.acceptClaim(claim);
      } else {
        claims.declineClaim(claim);
      }
    } finally {
      setBackdrop(false);
    }
  };

  const isSmartMedium = claim.triageDecision.processingType === "SmartMedium";

  let assignee: IdentityDto;
  if (isSmartMedium) {
    assignee = claim.estimationExpertAssignee;
  } else {
    assignee = claim.backOfficeAssignee;
  }

  const { t } = useTranslation("translation", { useSuspense: false });

  return (
    <>
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container>
        <Grid item xs={12}>
          <h1 className={classes.title}>
            {t("claim.title", {
              sapid: claim.claimSapId,
            })}{" "}
          </h1>
          <Typography variant="subtitle1">
            {t("claim.subtitle", {
              date: humanizeIso8601ToDate(claim.craftsmanJob.acceptedAt),
            })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box mt={3}>
            <Paper elevation={2}>
              <Box p={2}>
                <Grid container>
                  <Grid item md={6}>
                    <Typography variant="h6">{t("claim.auftrag")}</Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography align="right" variant="subtitle1">
                      {t("claim.auftragseingang", {
                        date: humanizeIso8601ToDate(
                          claim.craftsmanJob.requestedAt
                        ),
                      })}
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <FormLabel className={classes.label}>
                      {t("claim.bauteile")}
                    </FormLabel>
                  </Grid>
                  <Grid item md={4}>
                    <FormLabel className={classes.label}>
                      {t("claim.ursache")}
                    </FormLabel>
                  </Grid>
                  <Grid item md={4}>
                    <FormLabel className={classes.label}>
                      {t("claim.schadendatum")}
                    </FormLabel>
                  </Grid>
                  <Grid item md={4}>
                    <ul className={classes.ul}>
                      {claim.parts.map((part, index) => {
                        return <li key={index}>{part.name}</li>;
                      })}
                    </ul>
                  </Grid>
                  <Grid item md={4}>
                    <Typography>
                      {t("causes:cause.description", { context: cause })}
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography>
                      {humanizeIso8601ToDate(claim.damageOccuredAt)}
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <FormLabel>{t("claim.betroffenesGebäude")}</FormLabel>
                  </Grid>
                  <Grid item md={4}>
                    <FormLabel>{t("claim.schadenmelder")}</FormLabel>
                  </Grid>
                  <Grid item md={4}>
                    <FormLabel>{t("claim.sachbearbeiter")}</FormLabel>
                  </Grid>
                  <Grid item md={4}>
                    <Link
                      href={`https://www.google.com/maps/place/${contractAddressDisplay(
                        claim.contract
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <RoomIcon fontSize="small" className={classes.icon} />
                    </Link>
                    {contractAddressDisplay(claim.contract)}&nbsp;
                  </Grid>
                  <Grid item md={4}>
                    <Typography>
                      {claim.firstName} {claim.lastName}
                      <br />
                      {humanizeCustomerType(claim.customerType)}
                      <br />
                      <Link href={`tel:${claim.phone}`}>
                        <PhoneIcon fontSize="small" className={classes.icon} />{" "}
                        {claim.phone}
                      </Link>
                      <br />
                      <Link href={`tel:${claim.mobile}`}>
                        <SmartphoneIcon
                          fontSize="small"
                          className={classes.icon}
                        />{" "}
                        {claim.mobile}
                      </Link>
                      <br />
                      <Link href={`mailto:${claim.email}`}>
                        <EmailIcon fontSize="small" className={classes.icon} />{" "}
                        {claim.email}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    {assignee ? (
                      <Typography>
                        {assignee.firstName} {assignee.lastName}
                        <br />
                        <Link href={assignee.phoneNumber}>
                          <PhoneIcon
                            fontSize="small"
                            className={classes.icon}
                          />{" "}
                          {assignee.phoneNumber}
                        </Link>
                        <br />
                        <Link href={`mailto:${assignee.email}`}>
                          <EmailIcon
                            fontSize="small"
                            className={classes.icon}
                          />{" "}
                          {assignee.email}
                        </Link>
                      </Typography>
                    ) : (
                      <Typography>
                        <i>{t("claim.noAssignment")}</i>
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box my={3}>
                      <Divider />
                    </Box>
                  </Grid>
                  {claim.claimReportLinks.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <FormLabel>{t("claim.schadenhergang")}</FormLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHtml(
                                claim.claimReportLinks[0].causeOfLoss
                              ),
                            }}
                          ></span>
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {loadingAttachments && (
                    <Grid item xs={12} container justify="center">
                      <Grid item>
                        <Box my={4}>
                          <CircularProgress />
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  {attachments.length > 0 && (
                    <Grid item xs={12}>
                      <Box my={3}>
                        <Divider />
                      </Box>
                    </Grid>
                  )}
                  {damagePhotos.length > 0 && (
                    <Grid item xs={12}>
                      <FormLabel>{t("photos")}</FormLabel>
                      <GridList cellHeight={160} cols={6}>
                        {damagePhotos.map((tile) => (
                          <GridListTile key={tile.id} cols={1}>
                            <Link
                              href={getClaimAttachmentUrl(tile)}
                              target="_blank"
                            >
                              <img
                                src={getClaimAttachmentUrl(tile, true)}
                                alt={tile.name}
                              />
                            </Link>
                          </GridListTile>
                        ))}
                      </GridList>
                    </Grid>
                  )}
                  {otherAttachments.length > 0 && (
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <FormLabel>{t("files")}</FormLabel>
                        <TableContainer component={Paper}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>{t("file")}</TableCell>
                                <TableCell align="right">
                                  {t("claim.size")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {otherAttachments.map((attachment) => (
                                <TableRow key={attachment.id}>
                                  <TableCell component="td" scope="row">
                                    <Link
                                      href={getClaimAttachmentUrl(attachment)}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {attachment.name}
                                    </Link>
                                  </TableCell>
                                  <TableCell align="right">
                                    {humanizeBytes(attachment.size)}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                  )}
                  {claim.craftsmanJob.description && (
                    <>
                      <Grid item xs={12}>
                        <Box my={3}>
                          <Divider />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <FormLabel>{t("claim.commentByGvb")}</FormLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography style={{ whiteSpace: "pre-line" }}>
                          {claim.craftsmanJob.description}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Paper>

            {claim.craftsmanJob.state === "Requested" && (
              <Grid item xs={12} container justify="center" spacing={1}>
                <Grid item>
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => accept(true)}
                    >
                      {t("claim.auftragAnnehmen")}
                    </Button>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => accept(false)}
                    >
                      {t("claim.auftragAblehnen")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ClaimInfos;
