import React from "react";
import {
  Grid,
  makeStyles,
  Theme,
  createStyles,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
} from "@material-ui/core";
import { claims } from "state/claims";
import { humanizeIso8601ToDate } from "core/date_utils";
import GvbColors from "core/GvbColors";
import { useHistory, useParams } from "react-router-dom";
import { removeCollectionPrefix } from "core/raven_utils";
import { getApiUrl } from "core/api_client_factory";
import { ClaimDetailUrlParams } from "./claim_detail_url_params";

const useStyles = (selected) =>
  makeStyles((theme: Theme) =>
    createStyles({
      listItem: {
        "&:hover": {
          backgroundColor: theme.palette.background.default,
        },
        backgroundColor: selected
          ? GvbColors.selected
          : GvbColors.paperBackground,
        cursor: "pointer",
      },
      avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
      },
    })
  )();

type ClaimListItemPropTypes = {
  claimId: string;
  claimSapId: string;
  name: string;
  address: string;
  claimDate: string;
  isSelected: boolean;
};

function ClaimListItem(props: ClaimListItemPropTypes) {
  const history = useHistory();
  const { claimId } = useParams<ClaimDetailUrlParams>();

  const classes = useStyles(props.isSelected);

  const apiUrl = getApiUrl();

  const selectClaim = (newClaimId) => {
    if (removeCollectionPrefix(newClaimId).toLowerCase() === claimId) {
      return;
    }

    claims.setSelectedClaim(newClaimId);
    history.push(`/claims/${removeCollectionPrefix(newClaimId).toLowerCase()}`);
  };

  return (
    <>
      <ListItem
        button
        className={classes.listItem}
        onClick={() => selectClaim(props.claimId)}
      >
        <ListItemAvatar>
          <Avatar
            className={classes.avatar}
            alt="schadenfoto"
            src={`${apiUrl}/${props.claimId}/image`}
            variant="square"
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Grid container justify="space-between" spacing={3}>
              <Grid item>{props.claimSapId}</Grid>
              <Grid item>{humanizeIso8601ToDate(props.claimDate)}</Grid>
            </Grid>
          }
          secondary={
            <>
              {props.name}
              <br />
              {props.address}
            </>
          }
        ></ListItemText>
      </ListItem>
      <Divider />
    </>
  );
}

export default ClaimListItem;
