import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Steps from "./steps";
import ClaimInfos from "./claim_infos";
import NoClaimSelected from "./no_claim_selected";
import { useSimplux } from "@simplux/react";
import { claims } from "state/claims";
import { getClaim } from "api/api.claims";
import { Grid, CircularProgress } from "@material-ui/core";
import { CraftsmanJobState } from "api/dtos";
import { ClaimDetailUrlParams } from "./claim_detail_url_params";

function getStepNameFromCraftsmanJobState(
  craftsmanJobState: CraftsmanJobState
) {
  switch (craftsmanJobState) {
    case "Accepted":
      return "step01";
    case "AppointmentEntered":
      return "step02";
    case "QuoteEntered":
      return "step03";
    case "JobApproved":
      return "step04";
    case "Fixing":
      return "step05";
    case "Fixed":
      return "step06";
    default:
      return false;
  }
}

const ClaimDetail = () => {
  const { claimId } = useParams<ClaimDetailUrlParams>();
  const loadingDetailClaim = useSimplux(claims.loadingDetailClaim);
  const detailClaim = useSimplux(claims.detailClaim);
  const state = detailClaim?.craftsmanJob?.state;

  function tabIndexFromState(state: CraftsmanJobState) {
    switch (state) {
      case "Requested":
        return 0;
      case "Closed":
        return 2;
      default:
        return 1;
    }
  }

  useEffect(() => {
    async function loadClaim() {
      let claim = await getClaim(claimId);

      claims.setDetailClaim(claim);
      claims.setTabIndex(tabIndexFromState(claim.craftsmanJob.state));
      setTimeout(() => {
        claims.setExpandedStep(
          getStepNameFromCraftsmanJobState(claim.craftsmanJob.state)
        );
      }, 350);
    }

    if (claimId) {
      loadClaim();
    }
  }, [claimId, state]);

  return (
    <>
      {!claimId && <NoClaimSelected />}
      {!loadingDetailClaim && detailClaim && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ClaimInfos />
            </Grid>
            {detailClaim.craftsmanJob.state !== "Requested" && (
              <Grid item xs={12}>
                <Steps />
              </Grid>
            )}
          </Grid>
        </>
      )}
      {loadingDetailClaim && (
        <>
          <Grid
            container
            direction="column"
            style={{ minHeight: "100vh" }}
            justify="center"
            alignContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ClaimDetail;
