import { removeCollectionPrefix } from "core/raven_utils";
import { createApiClient } from "core/api_client_factory";
import { GetCraftsmen, GetCraftsman, GetMyCraftsmanLanguage, UpdateMyCraftsmanLanguage } from "api/dtos";

export function getCraftsmen(search: string = "") {
  let client = createApiClient();
  let request = new GetCraftsmen();

  if (search !== "") {
    request.query = search.trim() + "*";
  }

  request.orderBy = "Company";
  request.pageSize = 50;

  return client.get(request);
}

export function getCraftsman(id: string) {
  let client = createApiClient();
  let request = new GetCraftsman();

  request.id = removeCollectionPrefix(id);

  return client.get(request);
}

export function getCraftsmanLanguage() {
  let client = createApiClient();
  let request = new GetMyCraftsmanLanguage();

  return client.get(request);
}

export function setCraftsmanLanguage(language: string) {
  let client = createApiClient();
  let request = new UpdateMyCraftsmanLanguage();
  request.language = language;
  
  return client.put(request);
}
