import Step01InspectionDate from "./step-01-inspection-date";
import Step02Quote from "./step-02-quote";
import Step03Approval from "./step-03-approval";
import Step04RepairDate from "./step-04-repair-date";
import Step05FinishedWork from "./step-05-finished-work";
import useStepStyles from "./step_styles";
import Step06InvoiceAndClosing from "./step-06-invoice-and-closing";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import { useSimplux } from "@simplux/react";
import { claims } from "state/claims";
import { useTranslation } from "react-i18next";

export default function Steps() {
  const classes = useStepStyles();

  const claim = useSimplux(claims.detailClaim);

  const { t } = useTranslation("translation", { useSuspense: false });

  return (
    <div className={classes.root}>
      <Step01InspectionDate />
      <Step02Quote />
      <Step03Approval />
      <Step04RepairDate />
      <Step05FinishedWork />
      <Step06InvoiceAndClosing />

      {claim.craftsmanJob.state === "Closed" && (
        <Box mx={8} my={3}>
          <Alert severity="success">
            <AlertTitle>{t("step6.doneTitle")}</AlertTitle>
            {t("step6.doneText")}
          </Alert>
        </Box>
      )}
    </div>
  );
}
