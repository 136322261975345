import {
  createSimpluxModule,
  createMutations,
  createSelectors,
} from "@simplux/core";
import { CraftsmanDto } from "api/dtos";

const appStateModule = createSimpluxModule({
  name: "app_state",
  initialState: {
    isLoading: false,
    isInitialized: false,
    isAuthenticated: false,
    login: "",
    authenticationError: false,
    authenticationErrorMessage: "",
    roles: [] as Array<String>,
    craftsman: false as CraftsmanDto | false,
  },
});

export const appState = {
  ...appStateModule,
  ...createMutations(appStateModule, {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setNotAuthenticated(state) {
      state.isInitialized = true;
    },
    setAuthenticated(state, login: string, roles: Array<String>) {
      state.isAuthenticated = true;
      state.login = login;
      state.isInitialized = true;
      state.roles = roles;
    },
    setAuthenticationError(state, message) {
      state.authenticationError = true;
      state.authenticationErrorMessage = message;
      state.isInitialized = true;
    },
    clearAuthenticationError(state) {
      state.authenticationError = false;
      state.authenticationErrorMessage = "";
    },
    setCraftsman(state, craftsman: CraftsmanDto | false) {
      state.craftsman = craftsman;
    },
  }),
  ...createSelectors(appStateModule, {
    isLoading: ({ isLoading }) => isLoading,
    isInitialized: ({ isInitialized }) => isInitialized,
    isAuthenticated: ({ isAuthenticated }) => isAuthenticated,
    login: ({ login }) => login,
    authenticationError: ({ authenticationError }) => authenticationError,
    authenticationErrorMessage: ({ authenticationErrorMessage }) =>
      authenticationErrorMessage,
    craftsman: ({ craftsman }) => craftsman,
    canQueryClaims: ({ craftsman, roles }) => {
      if (!roles) {
        return false;
      }

      if (roles.indexOf("casehandler") >= 0) {
        return !!craftsman;
      }

      return true;
    },
    isCaseHandler: ({ roles }) => roles.indexOf("casehandler") >= 0,
  }),
};
