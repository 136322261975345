import { JsonServiceClient } from "@servicestack/client";
import Cookies from "js-cookie";

export function createApiClient() {
  const client = new JsonServiceClient(getApiUrl());

  client.exceptionFilter = (response, error) => {
    if (response?.status === 503) {
      window.location.reload();
  
      return;
    }
  
    throw error;
  };

  return client;
}

export function createApiClientWithToken(token: string) {
  const client = new JsonServiceClient(getApiUrl());
  client.setBearerToken(token);

  client.exceptionFilter = (response, error) => {
    if (response?.status === 503) {
      window.location.reload();
  
      return;
    }
  
    throw error;
  };
  
  return client;
}

export function getApiUrl() {
  switch (window.location.origin) {
    case "http://localhost:1340":
      const uiTestBackend = getUiTestBackend();

      if (uiTestBackend) {
        console.log("using ui test backend ", uiTestBackend);
        return uiTestBackend;
      }

      return "http://localhost:5000";

    case "https://supply.gvb.local":
      return "https://smarty-api.gvb.local";

    case "https://dev-supply.gvb.ch":
      return "https://dev-smarty-api.gvb.ch";

    case "https://supply.dev.scp.zuluplane.io":
      return "https://scp-api.dev.scp.zuluplane.io";

    case "https://supply.test.gvb.ch":
      return "https://scp-api.test.gvb.ch";

    case "https://test-supply.gvb.ch":
      return "https://test-smarty-api.gvb.ch";

    case "https://supply.gvb.ch":
      return "https://scp-api.gvb.ch";
  }
}

function getUiTestBackend() {
  if (!document.cookie) {
    return undefined;
  }

  return Cookies.get("ui-test");
}
