import { useMsal } from "@azure/msal-react";
import ModalCircularProgress from "components/modal_circular_progress";
import React, { useEffect } from "react";
import { OidcConfiguration } from "./msal_configuration";

const Login = () => {
  const { instance, accounts } = useMsal();

  useEffect(() => {
    instance.loginRedirect({ scopes: OidcConfiguration.clientScope });
  }, [instance, accounts]);

  return <ModalCircularProgress />;
};

export default Login;
