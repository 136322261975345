import { createApiClient } from "core/api_client_factory";
import { GetJobs, GetClaimPreview, PagedResult, ClaimListDto } from "api/dtos";
import { HttpMethods } from "@servicestack/client";
import { removeCollectionPrefix } from "core/raven_utils";
import { appState } from "state/app_state";

export function getClaims(query: string = "") {
  if (!appState.canQueryClaims()) {
    return Promise.resolve(new PagedResult<ClaimListDto>({ results: [] }));
  }

  let client = createApiClient();
  let request = new GetJobs();

  let craftsman = appState.craftsman();

  if (craftsman) {
    request.craftsmanId = craftsman.id;
  }

  if (query && query !== "") {
    request.query = query.trim() + "*";
  }

  request.orderBy = "-CreatedAt";
  request.pageSize = 2000;

  return client.get(request);
}

export function getClaimPreviewUrl(claimId: string): string {
  let client = createApiClient();
  let request = new GetClaimPreview();
  request.claimId = removeCollectionPrefix(claimId);

  return client.createUrlFromDto(HttpMethods.Get, request);
}
